import React from 'react'
import '../links/css/innovationOdyssey.css'
// import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';

function InnovationOdyssey() {
  return (
    <div className='innovation-odyssey'>
        <LightSpeed left>
        <div className="heading">
            <div><br /></div><span>Vintage vistas</span>
        </div>
        </LightSpeed>
        <LightSpeed right>
        <div className="desc">
        Why vintage vistas ? 
        Shilp ’25, organized by the Civil Engineering Society of IIT BHU, embraces the theme 
        <strong> “Vintage Vistas”</strong> to celebrate the grandeur and ingenuity of ancient architecture. This theme 
        is a tribute to the timeless engineering marvels that have shaped civilizations and continue to 
        inspire modern construction. From the majestic temples of India to the intricate aqueducts of 
        Rome, historical structures stand as testaments to the brilliance of early architects and 
        engineers.  
        Architecture is more than just structures; it is a reflection of culture, heritage, and identity. 
        Vintage Vistas highlights the significance of preserving and understanding these historical 
        masterpieces, as they offer insights into the evolution of design and construction. The fusion 
        of traditional architectural principles with modern technology has the potential to create 
        sustainable, aesthetically rich spaces that honor history while embracing innovation. By 
        revisiting the past, we aim to draw inspiration for the future, ensuring that the legacy of 
        architectural excellence continues to thrive.
        </div>
        </LightSpeed>
    </div>
  )
}

export default InnovationOdyssey